import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import { StrictMode, lazy } from 'react';
import ErrorBoundary from './components/ErrorBoundary';
import './index.scss';
import Login from './pages/Login';
import RestaurantInfo from './pages/RestaurantInfo/RestaurantInfo';
import Signup from './pages/Signup';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import HasAccess from './components/HasAccess';
import { PERMISSIONS } from './constants/constants';
import store from './redux/store';

const Coupons = lazy(() => import('./pages/Coupons'));

const CustomerOrders = lazy(() => import('./pages/CustomerOrders'));
const Customers = lazy(() => import('./pages/Customers'));
const LoginRequired = lazy(() => import('./middleware/LoginRequired'));
const Orders = lazy(() => import('./pages/Orders'));
const OrderDetail = lazy(() => import('./pages/OrderDetail'));
const Restaurants = lazy(() => import('./pages/Restaurants'));
const RestaurantDetail = lazy(() => import('./pages/RestaurantDetail'));
const RestaurantMenu = lazy(() => import('./pages/RestaurantMenu'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const NotFound = lazy(() => import('./components/NotFound'));
const SupportComponent = lazy(() => import('./pages/Support/SupportComponent'));

const CustomerDetails = lazy(() => import('./pages/CustomerDetails'));
const Delivery = lazy(() => import('./pages/Delivery/Delivery'));
const CouponDetails = lazy(() => import('./pages/CouponDetails'));
const Settings = lazy(() => import('./pages/Settings/Settings'));
const Roles = lazy(() => import('./pages/Roles/Roles'));
const Nutritionist = lazy(() => import('./pages/Nutritionist/Nutritionist'));
const Requests = lazy(
  () => import('./pages/Nutritionists/NewRequests/Requests'),
);
const PayoutDetails = lazy(() => import('./pages/PayoutDetails/PayoutDetails'));
const Nutritionists = lazy(() => import('./pages/Nutritionists/Nutritionists'));
const Meals = lazy(() => import('./pages/Meals/Meals'));
const NutritionistMenu = lazy(
  () => import('./pages/NutritionistMenu/NutritionistMenu'),
);
const Payouts = lazy(() => import('./pages//Payouts/Payouts'));

const ChatDialog = lazy(
  () => import('./pages/Support/ChatComponent/ChatDialog'),
);
const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: '#7F56D9',
              colorPrimaryHover: '#8f5ef7',
            },
            Menu: {
              itemSelectedBg: '#F9FAFB',
              itemSelectedColor: '#182230',
            },
            Tabs: {
              itemHoverColor: '#7F56D9',
            },
            Layout: {
              siderBg: '#fff',
            },
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <Router>
              <Routes>
                <Route element={<LoginRequired />}>
                  <Route element={<Dashboard />}>
                    <Route path='/:id/settings' element={<Settings />} />
                    <Route
                      path='/roles'
                      element={
                        <HasAccess
                          permissions={[
                            PERMISSIONS.COUPONS,
                            PERMISSIONS.DELIVERY,
                            PERMISSIONS.ORDERS,
                            PERMISSIONS.RESTAURANTS,
                            PERMISSIONS.CUSTOMER,
                            PERMISSIONS.SUPPORT,
                            PERMISSIONS.NUTRITIONIST,
                          ]}
                        >
                          <Roles />
                        </HasAccess>
                      }
                    />{' '}
                    <Route
                      path='/orders'
                      element={
                        <HasAccess permissions={[PERMISSIONS.ORDERS]}>
                          <Orders />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/customers'
                      element={
                        <HasAccess permissions={[PERMISSIONS.CUSTOMER]}>
                          <Customers />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/customer/:id'
                      element={
                        <HasAccess permissions={[PERMISSIONS.CUSTOMER]}>
                          <CustomerDetails />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/customer/:id/orders/'
                      element={
                        <HasAccess permissions={[PERMISSIONS.CUSTOMER]}>
                          <CustomerOrders />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/orders/:id'
                      element={
                        <HasAccess permissions={[PERMISSIONS.ORDERS]}>
                          <OrderDetail />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/restaurants'
                      element={
                        <HasAccess permissions={[PERMISSIONS.RESTAURANTS]}>
                          <Restaurants />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/restaurant-info/:id'
                      element={
                        <HasAccess permissions={[PERMISSIONS.RESTAURANTS]}>
                          <RestaurantInfo />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/restaurant/:id'
                      element={
                        <HasAccess permissions={[PERMISSIONS.RESTAURANTS]}>
                          <RestaurantDetail />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/restaurant-menu/:id'
                      element={
                        <HasAccess permissions={[PERMISSIONS.RESTAURANTS]}>
                          <RestaurantMenu />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/restaurant-meals/:id'
                      element={
                        <HasAccess permissions={[PERMISSIONS.RESTAURANTS]}>
                          <Meals />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/restaurant/:id/nutritionist-menu'
                      element={
                        <HasAccess permissions={[PERMISSIONS.RESTAURANTS]}>
                          <NutritionistMenu />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/restaurant/:id/payouts'
                      element={
                        <HasAccess permissions={[PERMISSIONS.RESTAURANTS]}>
                          <Payouts />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/restaurant/:id/payouts/:payoutId'
                      element={
                        <HasAccess permissions={[PERMISSIONS.RESTAURANTS]}>
                          <PayoutDetails />
                        </HasAccess>
                      }
                    />
                    <Route path='/' element={<LandingPage />} />
                    <Route
                      path='/coupons'
                      element={
                        <HasAccess permissions={[PERMISSIONS.COUPONS]}>
                          <Coupons />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/coupons/:id'
                      element={
                        <HasAccess permissions={[PERMISSIONS.COUPONS]}>
                          <CouponDetails />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/support'
                      element={
                        <HasAccess permissions={[PERMISSIONS.SUPPORT]}>
                          <SupportComponent />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/delivery'
                      element={
                        <HasAccess permissions={[PERMISSIONS.DELIVERY]}>
                          <Delivery />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/nutritionists'
                      element={
                        <HasAccess permissions={[PERMISSIONS.NUTRITIONIST]}>
                          <Nutritionists />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/nutritionists-requests'
                      element={
                        <HasAccess permissions={[PERMISSIONS.NUTRITIONIST]}>
                          <Requests />
                        </HasAccess>
                      }
                    />
                    <Route
                      path='/nutritionist/:id'
                      element={
                        <HasAccess permissions={[PERMISSIONS.NUTRITIONIST]}>
                          <Nutritionist />
                        </HasAccess>
                      }
                    />
                  </Route>
                </Route>
                <Route path='/login' element={<Login />} />
                <Route path='/signup' element={<Signup />} />
                <Route path='*' element={<NotFound />} />
              </Routes>
            </Router>
          </ErrorBoundary>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </ConfigProvider>
    </Provider>
  </StrictMode>,
);
