import { Navigate } from 'react-router-dom';
import { useLoginStatus } from '../../hooks/useLoginStatus';

function HasAccess({ permissions, children }) {
  const { user } = useLoginStatus();
  return permissions.every(permission =>
    user.permissions.includes(permission),
  ) ? (
    children
  ) : (
    <Navigate to={'/'} />
  );
}

export default HasAccess;
