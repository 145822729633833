import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import classes from './ErrorBoundary.module.scss';
const ErrorBoundary = props => {
  const { Title } = Typography;
  const [hasError, setHasError] = useState(false);

  const handleSetHasError = error => {
    setHasError(true);
  };

  useEffect(() => {
    const errorHandler = (error, errorInfo) => {
      setHasError(true);
    };

    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  if (hasError) {
    return (
      <div className={classes.ErrorBoundaryWrapper}>
        <div>
          <div className={classes.IconWrapper}>
            <Title level={1}>Warning</Title>
          </div>
          <div className={classes.ContentWrapper}>
            <Title level={5}>Oops, Something went wrong!</Title>
            <h4>Please try refreshing the page or try again after sometime.</h4>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {React.cloneElement(props.children, {
        onError: handleSetHasError,
      })}
    </>
  );
};

export default ErrorBoundary;
