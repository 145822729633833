import axiosInstance from '../axiosInstance';

const fetchRestaurants = async ({
  pageNumber = 1,
  pageSize = 5,
  searchQuery = '',
  status = '',
  dateFilter = '',
  city = [],
}) => {
  const endpoint = `/api/petpooja/restaurants?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&dateFilter=${dateFilter}&city=${city}&searchQuery=${searchQuery}`;
  const { data } = await axiosInstance.get(endpoint);
  return data;
};

const fetchFoodItems = async ({
  id,
  pageNumber = 1,
  pageSize = 5,
  searchQuery = '',
  status = '',
}) => {
  const endpoint = `/api/admin/restaurants/${id}/food-items?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&searchQuery=${searchQuery}`;

  const { data } = await axiosInstance.get(endpoint);

  return data;
};

const downloadExcelFile = async () => {
  const endpoint = `/api/admin/restaurants/export`;
  const { data } = await axiosInstance.get(endpoint, {
    responseType: 'arraybuffer',
  });
  return data;
};

const fetchOrderStats = async () => {
  const endpoint = `/api/admin/order-stats`;

  const { data } = await axiosInstance.get(endpoint);

  return data;
};

const fetchRestaurantWithId = async id => {
  const endpoint = `/api/petpooja/restaurant/${id}`;

  const { data } = await axiosInstance.get(endpoint);

  return data;
};

const fetchRestaurantCities = async () => {
  const endpoint = `/api/admin/restaurant-filter/cities`;

  const { data } = await axiosInstance.get(endpoint);

  return data;
};
const fetchStatsRestaurantDetail = async id => {
  const endpoint = `/api/admin/restaurants/:id/stats`;

  const { data } = await axiosInstance.get(endpoint);

  return data;
};

const banRestaurant = async (restaurantId, reason) => {
  const endpoint = `/api/admin/restaurants/${restaurantId}/ban`;
  const { data } = await axiosInstance.patch(endpoint, { reason });
  return data;
};

const unbanRestaurant = async (restaurantId, reason) => {
  const endpoint = `/api/admin/restaurants/${restaurantId}/unban`;

  const { data } = await axiosInstance.patch(endpoint, { reason });

  return data;
};

const toggleFoodAvailability = async foodId => {
  const endpoint = `/api/admin/foodItem/${foodId}`;

  const { data } = await axiosInstance.post(endpoint);

  return data;
};

const archiveFoodItem = async (id, reason) => {
  const endpoint = `/api/admin/foodItem/${id}/archive`;

  const { data } = await axiosInstance.patch(endpoint, { reason });

  return data;
};

const unArchiveFoodItem = async (id, reason) => {
  const endpoint = `/api/admin/foodItem/${id}/unarchive`;

  const { data } = await axiosInstance.patch(endpoint, { reason });

  return data;
};

const toggleRestaurantOnlineStatus = (restaurantId, isOnline) => {
  const endpoint = `/api/admin/restaurant/online/${restaurantId}`;

  const { data } = axiosInstance.patch(endpoint, {
    isOnline,
  });

  return data;
};

const getRestaurantFoodItems = async ({
  pageNumber = 1,
  pageSize = 5,
  restId,
  search = '',
}) => {
  try {
    const endpoint = `/api/petpooja/foodItem/restaurant/${restId}?pageNumber=${pageNumber}&pageSize=${pageSize}${search ? `&search=${search}` : ''}`;
    const { data } = await axiosInstance.get(endpoint);

    // add key as id in every food item
    let tempData = data.items.map(item => {
      return {
        ...item,
        key: item.itemid,
      };
    });
    return { items: tempData, page: data.page };
  } catch (error) {
    return error.response;
  }
};

export const createRestaurant = async formData => {
  const response = await axiosInstance.post(
    'api/admin/create-restaurant',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

const updateRestaurant = async (id, updatedData) => {
  const response = await axiosInstance.patch(
    `api/admin/restaurants/${id}`,
    updatedData,
  );
  return response.data;
};

const getRestaurantMeals = async ({
  pageNumber = 1,
  pageSize = 5,
  id,
  status,
}) => {
  try {
    const endpoint = `/api/petpooja/restaurant/${id}/meals?pageNumber=${pageNumber}&pageSize=${pageSize}&statusFilter=${status}`;
    const { data } = await axiosInstance.get(endpoint);
    return data;
  } catch (error) {
    return error.response;
  }
};

const getRestaurantCategories = async id => {
  try {
    const endpoint = `/api/petpooja/restaurant/${id}/categories`;
    const { data } = await axiosInstance.get(endpoint);
    return data;
  } catch (error) {
    return error.response;
  }
};

const getRestaurantFoodItemsByCategoryId = async ({
  restId,
  categoryId,
  pageNumber = 1,
  pageSize = 5,
}) => {
  try {
    const endpoint = `/api/petpooja/foodItem/restaurants/${restId}/categories/${categoryId}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const { data } = await axiosInstance.get(endpoint);
    return data;
  } catch (error) {
    return error.response;
  }
};

const toggleInStock = async (itemid, inStock, restID) => {
  return axiosInstance.put(`/api/admin/restaurant/petpooja-item/${itemid}`, {
    inStock,
    restID,
  });
};

const getFoodItem = async (id, restID) => {
  try {
    const endpoint = `/api/admin/restaurant/${restID}/nutrition/${id}`;
    const { data } = await axiosInstance.get(endpoint);
    return data;
  } catch (error) {
    return error.response;
  }
};

const getCounts = async restId => {
  try {
    const response = await axiosInstance.get('/api/admin/restaurant/counts', {
      params: { restId },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      `Error fetching counts: ${error.response?.data?.message || error.message}`,
    );
  }
};

const updateNutrition = async (itemId, variationId, nutritionData) => {
  const response = await axiosInstance.put(
    `/api/admin/restaurant/${itemId}/${variationId}/nutrition`,
    nutritionData,
  );
  return response.data;
};

const updateNutritionV2 = async (itemid, restID, nutritionData) => {
  try {
    const response = await axiosInstance.put(
      '/api/admin/restaurant/v2/update-nutrition',
      {
        itemid,
        nutritionData,
        restID,
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error updating nutrition:', error);
    throw error;
  }
};

const getPayoutsForARestaurant = async ({ restID, pageSize, pageNumber }) => {
  try {
    const response = await axiosInstance.get(
      `/api/admin/restaurant/payouts/${restID}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
    return response.data;
  } catch (error) {
    console.error('error getting payouts:', error);
    throw error;
  }
};

const getPayoutDetailsForARestaurant = async ({
  restID,
  id,
  pageSize,
  pageNumber,
}) => {
  try {
    const response = await axiosInstance.get(
      `/api/admin/restaurant/payouts/${restID}/${id}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
    return response.data;
  } catch (error) {
    console.error('error getting payouts:', error);
    throw error;
  }
};

const updatePaymentForPayout = async ({
  restID,
  week,
  transactionId,
  settledAmount,
}) => {
  const response = await axiosInstance.put(
    `/api/admin/restaurant/payouts/payment/${restID}/${week}/`,
    {
      transactionId,
      settledAmount,
    },
  );
  return response.data;
};

const exportPayoutsToExcel = async () => {
  try {
    const date = new Date()
      .toISOString()
      .replace(/[:T]/g, '-')
      .replace(/\..+/, '');
    const filename = `Payouts-${date}.xlsx`;

    const response = await axiosInstance.get(
      'api/admin/restaurant/export/payouts',
      {
        responseType: 'blob',
      },
    );

    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', filename);
    document.body.appendChild(fileLink);

    fileLink.click();

    document.body.removeChild(fileLink);
    window.URL.revokeObjectURL(fileURL);
  } catch (error) {
    console.error('Error downloading the Support Excel file:', error);
  }
};

export {
  archiveFoodItem,
  banRestaurant,
  downloadExcelFile,
  exportPayoutsToExcel,
  fetchFoodItems,
  fetchOrderStats,
  fetchRestaurantCities,
  fetchRestaurantWithId,
  fetchRestaurants,
  fetchStatsRestaurantDetail,
  getCounts,
  getFoodItem,
  getPayoutDetailsForARestaurant,
  getPayoutsForARestaurant,
  getRestaurantCategories,
  getRestaurantFoodItems,
  getRestaurantFoodItemsByCategoryId,
  getRestaurantMeals,
  toggleFoodAvailability,
  toggleInStock,
  toggleRestaurantOnlineStatus,
  unArchiveFoodItem,
  unbanRestaurant,
  updateNutrition,
  updateNutritionV2,
  updatePaymentForPayout,
  updateRestaurant,
};
