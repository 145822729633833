import { configureStore } from '@reduxjs/toolkit';
import deliveryReducer from './features/delivery/deliverySlice';
import orderReducer from './features/orders/orderSlice';

const store = configureStore({
  reducer: {
    order: orderReducer,
    delivery: deliveryReducer,
  },
});

export default store;
