import { UploadOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Typography,
  Upload,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { MdImageNotSupported } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { updateRestaurant } from '../../../api/restaurants/restaurants';
import eggIcon from '../../../assets/icons/egg.png';
import vegIcon from '../../../assets/icons/veg.png';
import nonVegIcon from '../../../assets/images/non-veg.png';
import { formatDate } from '../../../utils';
import css from './EditRestaurantModal.module.scss';

const { Title, Text } = Typography;

const EditRestaurantModal = ({
  visible,
  onCancel,
  onUpdate,
  restaurantData,
  restaurantAddressData,
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [newCoverPic, setNewCoverPic] = useState(restaurantData.coverPic);
  const formattedDate = formatDate(restaurantData.createdAt);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: updatedData => updateRestaurant(id, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries(['restaurant', id]);
      message.success('Restaurant updated successfully');
      onCancel();
    },
    onError: () => {
      message.error('Failed to update restaurant');
    },
  });

  const handleUpdate = () => {
    form.validateFields().then(values => {
      const updatedValues = {
        ...values,
        coverPic: newCoverPic,
        cuisine: values.cuisineType
          ? values.cuisineType.split(',').map(c => c.trim())
          : [],
        addresses: [
          {
            building: values.building,
            address: values.address,
            address2: values.address2,
            city: values.city,
            state: values.state,
            pincode: values.pincode,
            addressType: 'HOME',
          },
        ],
      };

      // Remove undefined fields
      Object.keys(updatedValues).forEach(
        key => updatedValues[key] === undefined && delete updatedValues[key],
      );

      mutation.mutate(updatedValues);
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...restaurantData,
      ...restaurantAddressData,
    });
  }, [restaurantData, restaurantAddressData, form]);

  return (
    <Modal
      open={visible}
      title={
        <div>
          <div>Meals</div>
          <div className={css.id}>
            {id} | {formattedDate.date}
          </div>
        </div>
      }
      onCancel={onCancel}
      footer={[
        <Button key='cancel' onClick={onCancel}>
          Cancel
        </Button>,
        <Button key='update' type='primary' onClick={handleUpdate}>
          Update
        </Button>,
      ]}
      className={css.modal}
      bodyStyle={{ height: '70vh', overflowY: 'scroll' }}
      width='60%'
    >
      <Form
        layout='vertical'
        form={form}
        initialValues={{ ...restaurantData, ...restaurantAddressData }}
      >
        <Row>
          <Col span={16}>
            <Form.Item>
              <Row>
                <Image
                  width={100}
                  height={100}
                  src={newCoverPic}
                  fallback={<MdImageNotSupported />}
                  style={{ borderRadius: 8 }}
                />
              </Row>
              <Row>
                <Upload
                  name='coverPic'
                  showUploadList={false}
                  beforeUpload={file => {
                    const reader = new FileReader();
                    reader.onload = e => setNewCoverPic(e.target.result);
                    reader.readAsDataURL(file);
                    return false;
                  }}
                >
                  <Button icon={<UploadOutlined />} style={{ marginTop: 10 }}>
                    Replace Image
                  </Button>
                </Upload>
              </Row>
            </Form.Item>
            <Form.Item name='name' label='Restaurant Name'>
              <Input />
            </Form.Item>

            <Form.Item>
              <Row align='middle'>
                <Col>
                  <Image width={24} height={24} src={vegIcon} alt='Veg' />
                  <Text className={css.iconText}>Veg</Text>
                </Col>
                <Col>
                  <Image
                    width={24}
                    height={24}
                    src={nonVegIcon}
                    alt='Non-Veg'
                  />
                  <Text className={css.iconText}>Non-Veg</Text>
                </Col>
                <Col>
                  <Image width={24} height={24} src={eggIcon} alt='Egg' />
                  <Text className={css.iconText}>Egg</Text>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item name='cuisineType' label='Cuisine Type'>
              <Input />
            </Form.Item>

            <Form.Item name='city' label='Contact for Customers Enquiry'>
              <Input />
            </Form.Item>
            <Form.Item name='state' label='Primary Contact for Kraverich'>
              <Input />
            </Form.Item>
            <Divider style={{ width: 350 }} />
            <Text style={{ fontWeight: 700, fontSize: 18 }}> Menu</Text>
            <Form.Item name='cuisineType' label='Online Menu in Excel format'>
              <Input />
            </Form.Item>
            <Divider />
            <Text style={{ fontWeight: 700, fontSize: 18 }}> Address</Text>
            <Form.Item name='googleMapLink' label='Google Map'>
              <Row align='middle'>
                <a href={restaurantData.googleMapLink}>google map link</a>
                <Input />
              </Row>
            </Form.Item>
            <Form.Item name='building' label='Building'>
              <Input />
            </Form.Item>
            <Form.Item name='address' label='Address'>
              <Input />
            </Form.Item>
            <Form.Item name='address2' label='Address Line 2'>
              <Input />
            </Form.Item>
            <Form.Item name='city' label='City'>
              <Input />
            </Form.Item>
            <Form.Item name='state' label='State'>
              <Input />
            </Form.Item>
            <Form.Item name='pincode' label='Pincode'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={1}>
            <Divider type='vertical' style={{ height: '100%' }} />
          </Col>
          <Col span={7}>
            <Form layout='vertical'>
              <Text style={{ fontWeight: 700, fontSize: 18 }}>
                {' '}
                Owner details
              </Text>
              <Form.Item name='operationalHours' label='Operational Hours'>
                <Input />
              </Form.Item>
              <Form.Item name='ownerMobileNumber' label='Owner Mobile'>
                <Input />
              </Form.Item>
              <Form.Item name='ownerFullName' label='Owner Full Name'>
                <Input />
              </Form.Item>
              <Form.Item name='ownerEmail' label='Owner Email'>
                <Input />
              </Form.Item>
              <Divider style={{ width: '100%' }} />
              <Text style={{ fontWeight: 700, fontSize: 18 }}>
                {' '}
                Bank Account Details
              </Text>
              <Form.Item name='accountHolderName' label='Account Holder Name'>
                <Input />
              </Form.Item>
              <Form.Item name='accountNumber' label='Account Number'>
                <Input />
              </Form.Item>
              <Row>
                <Form.Item name='ifsc' label='IFSC'>
                  <Input />
                </Form.Item>
                <Form.Item
                  name='cheque'
                  label='Passbook or Cancelled Cheque'
                  style={{ marginLeft: 60 }}
                >
                  <Input />
                </Form.Item>
              </Row>
              <Divider style={{ width: '100%' }} />
              <Text style={{ fontWeight: 700, fontSize: 18 }}> Documents</Text>
              <Row>
                <Form.Item name='panNumber' label='Owner’s PAN number'>
                  <Input />
                </Form.Item>
                <Form.Item
                  name='panDocument'
                  label='PAN Document'
                  style={{ marginLeft: 60 }}
                >
                  <Input />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item name='gstinNumber' label='Restaurant GSTIN number'>
                  <Input />
                </Form.Item>
                <Form.Item
                  name='gstinDocument'
                  label='GSTIN Document'
                  style={{ marginLeft: 34 }}
                >
                  <Input />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item name='fssaiNumber' label='FSSAI license number'>
                  <Input />
                </Form.Item>
                <Form.Item
                  name='fssaiDocument'
                  label='FSSAI Document'
                  style={{ marginLeft: 60 }}
                >
                  <Input />
                </Form.Item>
              </Row>
            </Form>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditRestaurantModal;
