import { Button, Flex, Input, Select, Typography, message } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginStatus } from '../../hooks/useLoginStatus';

import axios from 'axios';
import { ReactSVG } from 'react-svg';
import Logo from '../../assets/images/kraverich-logo.svg';
import css from './Signup.module.scss';

export default function Signup() {
  const { Title } = Typography;
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('male');
  const { isLoggedIn } = useLoginStatus();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');

  const validateEmail = email => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSignup = async () => {
    setEmailError('');
    setNameError('');

    let isFormValid = true;

    if (!validateEmail(email)) {
      setEmailError('*Please enter a correct email address');
      isFormValid = false;
    }

    if (!/^[a-zA-Z\s]*$/.test(name)) {
      setNameError('*Full name must contain only letters');
      isFormValid = false;
    }

    if (password !== confirmPassword) {
      message.warning('Passwords do not match');
      isFormValid = false;
    }

    if (!isFormValid) return;

    const postData = {
      phone: phoneNumber,
      email,
      gender,
      name,
      password,
      confirmPassword,
      role: 'ADMIN',
    };

    try {
      const endpoint = '/api/admin/v2/signup';
      const register = await axios.post(
        process.env.REACT_APP_BASE_URL + endpoint,
        postData,
      );

      message.success(register.data?.message);

      if (register.status) {
        navigate('/login');
      }
    } catch (error) {
      message.error('User with this phone number already exists');
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard');
    }
  }, []);

  return (
    <Flex align='center' justify='center' className={css.mainWrapper}>
      <Flex gap={10} align='center' vertical className={css.container}>
        <ReactSVG src={Logo} className={css.logo} />
        <Title level={2}>Admin Signup</Title>
        <p className={css.tagline}>
          Welcome! Please create an account to access the dashboard.
        </p>
        <Input
          type='text'
          placeholder='Fullname'
          className={css.input}
          value={name}
          required
          onChange={e => setName(e.target.value)}
        />
        {nameError && (
          <Typography.Text className={css.errorText}>
            {nameError}
          </Typography.Text>
        )}
        <Input
          type='number'
          className={css.input}
          placeholder='Phone number'
          value={phoneNumber}
          required
          onChange={e => setPhoneNumber(e.target.value)}
        />

        <Input
          type='email'
          className={css.input}
          placeholder='Email address'
          value={email}
          required
          onChange={e => setEmail(e.target.value)}
        />
        {emailError && (
          <Typography.Text type='danger'>{emailError}</Typography.Text>
        )}

        <Input.Password
          type='password'
          className={css.input}
          placeholder='Password'
          value={password}
          required
          onChange={e => {
            setPassword(e.target.value);
          }}
        />
        <Input.Password
          className={css.input}
          type='password'
          placeholder='Confirm Password'
          value={confirmPassword}
          required
          onChange={e => setConfirmPassword(e.target.value)}
        />
        <Select
          className={css.input}
          options={[
            {
              label: 'Male',
              value: 'male',
            },
            {
              label: 'Female',
              value: 'female',
            },
            {
              label: 'Others',
              value: 'other',
            },
          ]}
          value={gender}
          onChange={e => setGender(e)}
        />

        <Button
          className={css.button}
          onClick={handleSignup}
          type='primary'
          size='large'
        >
          Register
        </Button>
        <p>
          Already have an account?{' '}
          <Link className={css.signInLink} to='/login'>
            Login
          </Link>
        </p>
      </Flex>
    </Flex>
  );
}
