import { useQuery } from '@tanstack/react-query';

import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Image,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';

import eggIcon from '../../assets/icons/egg.png';
import vegIcon from '../../assets/icons/veg.png';
import nonVegIcon from '../../assets/images/non-veg.png';

import { useState } from 'react';
import { LuPen } from 'react-icons/lu';
import { MdImageNotSupported } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { fetchRestaurantWithId } from '../../api/restaurants/restaurants';
import EditRestaurantModal from './EditRestaurantModal/EditRestaurantModal';
import css from './RestaurantInfo.module.scss';

const { Title, Text } = Typography;

const useGetRestaurant = id => {
  return useQuery({
    queryKey: ['restaurant', id],
    queryFn: () => fetchRestaurantWithId(id),
    enabled: !!id,
  });
};

const RestaurantInfo = () => {
  const { id } = useParams();
  const { data: restaurant, isLoading: isLoadingRestaurant } =
    useGetRestaurant(id);
  const [isModalVisible, setIsModalVisible] = useState(false);

  if (isLoadingRestaurant) {
    return (
      <div style={{ textAlign: 'center', marginTop: 48 }}>
        <Spin />
      </div>
    );
  }

  const restaurantData = restaurant?.resturant || {};
  const restaurantAddressData = restaurant?.details || {};

  const handleEditClick = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUpdate = updatedValues => {
    setIsModalVisible(false);
  };

  return (
    <Space
      direction='vertical'
      size='large'
      style={{ width: '100%', padding: 24 }}
    >
      <Row justify='space-between' align='middle'>
        <Col>
          <Row>
            <Title level={2}>{restaurantData?.name}</Title>
          </Row>
          <Text style={{ color: '#475467', fontSize: 16 }}>
            {restaurantData?.id}
          </Text>
        </Col>
        <Row>
          <Button
            icon={<LuPen size={16} />}
            style={{
              float: 'right',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 5,
            }}
            // onClick={handleEditClick}
          >
            Edit
          </Button>
        </Row>
      </Row>

      <Row gutter={16}>
        <Col span={16}>
          <Card className={css.cardStyle}>
            <Row>
              <Form layout='vertical'>
                <Form.Item>
                  <Row>
                    <Image
                      width={100}
                      height={100}
                      src={restaurantData?.coverPic}
                      fallback={<MdImageNotSupported />}
                      style={{ borderRadius: 8 }}
                    />
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Text className={css.restaurantName}>
                    {restaurantData?.name}
                  </Text>
                </Form.Item>

                <Form.Item>
                  <Row align='middle'>
                    <Col>
                      <Image width={24} height={24} src={vegIcon} alt='Veg' />
                      <Text className={css.iconText}>Veg</Text>
                    </Col>
                    <Col>
                      <Image
                        width={24}
                        height={24}
                        src={nonVegIcon}
                        alt='Non-Veg'
                      />
                      <Text className={css.iconText}>Non-Veg</Text>
                    </Col>
                    <Col>
                      <Image width={24} height={24} src={eggIcon} alt='Egg' />
                      <Text className={css.iconText}>Egg</Text>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item>
                  <Row>
                    {/* {restaurantData?.cuisine.map(item => (
                      <Text className={css.cuisine}>{item},</Text>
                    ))} */}
                  </Row>
                </Form.Item>

                <Form.Item
                  label={
                    <p
                      style={{ fontSize: '12px', color: '#667085', height: 0 }}
                    >
                      Contact for Customers Enquiry
                    </p>
                  }
                >
                  <Text className={css.textElement}>
                    {restaurantAddressData?.contact}
                  </Text>
                </Form.Item>
                <Form.Item
                  label={
                    <p
                      style={{ fontSize: '12px', color: '#667085', height: 0 }}
                    >
                      Primary Contact for Kraverich
                    </p>
                  }
                >
                  <Text className={css.textElement}>
                    {restaurantAddressData?.contact}
                  </Text>
                </Form.Item>
                <Divider style={{ width: 350 }} />
                <Text style={{ fontWeight: 700, fontSize: 18 }}> Menu</Text>
                <Form.Item
                  label={
                    <p
                      style={{ fontSize: '12px', color: '#667085', height: 0 }}
                    >
                      Online Menu in Excel format
                    </p>
                  }
                >
                  <Text className={css.textElement}>
                    {restaurantData?.cuisineType}
                  </Text>
                </Form.Item>
                <Divider />
                <Text style={{ fontWeight: 700, fontSize: 18 }}> Address</Text>
                <Form.Item
                  label={
                    <p
                      style={{ fontSize: '12px', color: '#667085', height: 0 }}
                    >
                      Google Map
                    </p>
                  }
                >
                  <Row align='middle'>
                    <a src={restaurantAddressData.googleMap}>google map link</a>
                  </Row>
                </Form.Item>
                <Form.Item
                  label={
                    <p
                      style={{ fontSize: '12px', color: '#667085', height: 0 }}
                    >
                      Address
                    </p>
                  }
                >
                  <Text className={css.textElement}>
                    {restaurantAddressData?.address} ,
                  </Text>
                  <Text className={css.textElement}>
                    {restaurantAddressData?.city} ,
                  </Text>
                  <Text className={css.textElement}>
                    {restaurantAddressData?.state}
                  </Text>
                  <Row>
                    <Text className={css.textElement}>
                      {restaurantAddressData?.country} ,
                    </Text>
                  </Row>
                </Form.Item>
              </Form>
              <Divider type='vertical' style={{ height: '140vh' }} />
              <Flex style={{ marginLeft: 40 }}>
                <Form layout='vertical'>
                  <Text style={{ fontWeight: 700, fontSize: 18 }}>
                    {' '}
                    Owner details
                  </Text>
                  <Form.Item
                    label={
                      <p
                        style={{
                          fontSize: '12px',
                          color: '#667085',
                          height: 0,
                        }}
                      >
                        Restaurant Owner Name
                      </p>
                    }
                  >
                    {/* <Text className={css.textElement}>
                      {restaurantData?.operationalHours[0]} -{' '}
                      {restaurantData?.operationalHours[1]}
                    </Text> */}
                  </Form.Item>
                  <Form.Item
                    label={
                      <p
                        style={{
                          fontSize: '12px',
                          color: '#667085',
                          height: 0,
                        }}
                      >
                        Restaurant Owner Mobile
                      </p>
                    }
                  >
                    <Text className={css.textElement}>
                      {`+91`} {restaurantData?.phone}
                    </Text>
                  </Form.Item>
                  <Form.Item
                    label={
                      <p
                        style={{
                          fontSize: '12px',
                          color: '#667085',
                          height: 0,
                        }}
                      >
                        Restaurant Owner Email
                      </p>
                    }
                  >
                    <Text className={css.textElement}>
                      {restaurantData?.email}
                    </Text>
                  </Form.Item>
                  <Divider style={{ width: 350 }} />
                  <Text style={{ fontWeight: 700, fontSize: 18 }}>
                    {' '}
                    Bank Account Details
                  </Text>
                  <Form.Item
                    label={
                      <p
                        style={{
                          fontSize: '12px',
                          color: '#667085',
                          height: 0,
                        }}
                      >
                        Account Holder Name
                      </p>
                    }
                  >
                    NA
                  </Form.Item>
                  <Form.Item
                    label={
                      <p
                        style={{
                          fontSize: '12px',
                          color: '#667085',
                          height: 0,
                        }}
                      >
                        Account Number
                      </p>
                    }
                  >
                    NA
                  </Form.Item>
                  <Row>
                    <Form.Item
                      label={
                        <p
                          style={{
                            fontSize: '12px',
                            color: '#667085',
                            height: 0,
                          }}
                        >
                          IFSC
                        </p>
                      }
                    >
                      NA
                    </Form.Item>
                    <Form.Item
                      label={
                        <p
                          style={{
                            fontSize: '12px',
                            color: '#667085',
                            height: 0,
                          }}
                        >
                          Passbook or Cancelled Cheque
                        </p>
                      }
                      style={{ marginLeft: 60 }}
                    >
                      NA
                    </Form.Item>
                  </Row>
                  <Divider style={{ width: 350 }} />
                  <Text style={{ fontWeight: 700, fontSize: 18 }}>
                    {' '}
                    Documents
                  </Text>
                  <Row>
                    <Form.Item
                      label={
                        <p
                          style={{
                            fontSize: '12px',
                            color: '#667085',
                            height: 0,
                          }}
                        >
                          Owner’s PAN number
                        </p>
                      }
                    >
                      NA
                    </Form.Item>
                    <Form.Item
                      label={
                        <p
                          style={{
                            fontSize: '12px',
                            color: '#667085',
                            height: 0,
                          }}
                        >
                          PAN Document
                        </p>
                      }
                      style={{ marginLeft: 60 }}
                    >
                      NA
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label={
                        <p
                          style={{
                            fontSize: '12px',
                            color: '#667085',
                            height: 0,
                          }}
                        >
                          Restaurant GSTIN number
                        </p>
                      }
                    >
                      NA
                    </Form.Item>
                    <Form.Item
                      label={
                        <p
                          style={{
                            fontSize: '12px',
                            color: '#667085',
                            height: 0,
                          }}
                        >
                          GSTIN Document
                        </p>
                      }
                      style={{ marginLeft: 34 }}
                    >
                      NA
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label={
                        <p
                          style={{
                            fontSize: '12px',
                            color: '#667085',
                            height: 0,
                          }}
                        >
                          FSSAI license number
                        </p>
                      }
                    >
                      NA
                    </Form.Item>
                    <Form.Item
                      label={
                        <p
                          style={{
                            fontSize: '12px',
                            color: '#667085',
                            height: 0,
                          }}
                        >
                          FSSAI Document
                        </p>
                      }
                      style={{ marginLeft: 60 }}
                    >
                      NA
                    </Form.Item>
                  </Row>
                </Form>
              </Flex>
            </Row>
          </Card>
        </Col>
      </Row>
      <EditRestaurantModal
        visible={isModalVisible}
        onCancel={handleCancel}
        onUpdate={handleUpdate}
        restaurantData={restaurantData}
        restaurantAddressData={restaurantAddressData}
      />
    </Space>
  );
};

export default RestaurantInfo;
