import { createSlice } from '@reduxjs/toolkit';

const orderSlice = createSlice({
  name: 'orderAlerts',
  initialState: {
    orderAlerts: {},
  },
  reducers: {
    setOrderAlerts: (state, action) => {
      state.orderAlerts = action.payload;
    },
  },
});

export const { setOrderAlerts } = orderSlice.actions;

export default orderSlice.reducer;
