import { Breadcrumb, Button, Divider, Flex, Popover, Typography } from 'antd';
import { LuBell, LuLayout } from 'react-icons/lu';
import { RiUser3Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { PERMISSIONS } from '../../constants/constants';
import { useLoginStatus } from '../../hooks/useLoginStatus';
import css from './Header.module.scss';
import { useState } from 'react';
function Header() {
  const { user } = useLoginStatus();

  const paths = [
    {
      href: '/',
      title: <LuLayout size={30} />,
    },
  ];
  const navigate = useNavigate();
  const { Title } = Typography;
  const [menuPopup, setMenuPopup] = useState(false);
  return (
    <Flex align='center' justify={'space-between'} className={css.header}>
      <Breadcrumb items={paths} />
      <Flex align={'center'} gap={20}>
        <Flex align={'center'} justify={'center'} className={css.actionIcon}>
          <LuBell size={20} />
        </Flex>
        <Popover
          open={menuPopup}
          onOpenChange={e => {
            setMenuPopup(e);
          }}
          placement='bottomRight'
          content={
            <Flex vertical gap={5} className={css.popover}>
              <div>
                <Title level={5}>{user.name}</Title>
                <span>{user.email}</span>
              </div>
              <Divider className={css.divider} />
              <Button
                type='text'
                className={css.actionButton}
                onClick={() => {
                  setMenuPopup(false);
                  navigate(`/${user.userId}/settings`);
                }}
              >
                Settings
              </Button>{' '}
              {user.role === PERMISSIONS.SUPERADMIN && (
                <Button
                  type='text'
                  className={css.actionButton}
                  onClick={() => {
                    setMenuPopup(false);

                    navigate(`/roles`);
                  }}
                >
                  Manage Role
                </Button>
              )}
              <Button
                type='text'
                className={css.actionButton}
                onClick={() => {
                  localStorage.removeItem('token');
                  navigate('/login');
                }}
              >
                Logout
              </Button>
            </Flex>
          }
        >
          <Flex
            align={'center'}
            justify={'center'}
            className={`${css.actionIcon} ${css.userIcon}`}
          >
            <RiUser3Line size={20} />
          </Flex>
        </Popover>
      </Flex>
    </Flex>
  );
}

export default Header;
