import { Button, Flex, Input, Typography, message } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { LuEye, LuEyeOff } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import Logo from '../../assets/images/kraverich-logo.svg';
import { useLoginStatus } from '../../hooks/useLoginStatus';
import css from './Login.module.scss';

export default function Login() {
  localStorage.clear();
  const { Title } = Typography;
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const { isLoggedIn } = useLoginStatus();

  const handleLogin = async () => {
    try {
      const endpoint = '/api/admin/v2/signin';
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + endpoint,
        {
          phone: phoneNumber,
          password: password,
        },
      );
      if (!response) {
        message.warning(response.data.message);
        return;
      }
      localStorage.setItem('token', response.data.token);
      navigate('/');
    } catch (error) {
      message.error(error.response?.data?.message);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, []);

  return (
    <Flex align='center' justify='center' className={css.mainWrapper}>
      <Flex align='center' vertical className={css.container}>
        <ReactSVG src={Logo} className={css.logo} />
        <Title level={2}>Admin Login</Title>
        <p className={css.tagline}>
          Welcome back! Please login to your account to access the dashboard.
        </p>
        <Input
          className={css.input}
          type='number'
          placeholder='Phone Number'
          size='large'
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
        />
        <Input.Password
          iconRender={visible => (visible ? <LuEye /> : <LuEyeOff />)}
          className={css.input}
          type='password'
          placeholder='Password'
          size='large'
          value={password}
          onChange={e => setPassword(e.target.value)}
        />

        <Button onClick={handleLogin} type='primary' className={css.button}>
          Login
        </Button>
        <p className={css.accountline}>
          Don't have an account?{' '}
          <Link className={css.signUpLink} to='/signup'>
            Sign up
          </Link>
        </p>
      </Flex>
    </Flex>
  );
}
