// formate iso date to an object of 12hr time and date
export const formatDate = (dateString, isTimestamp = false) => {
  if (!dateString) return '';
  var date = isTimestamp ? new Date(dateString * 1000) : new Date(dateString);

  var time = date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  const dateStr = date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return { time: time, date: dateStr };
};

export const formatDateTime = (dateString, timeString, slot) => {
  const date = new Date(`${dateString}T${timeString}`);

  // Format the date as "08 Oct 2024"
  const formattedDate = date
    .toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    .replace(',', '');

  // If slot is provided, extract the time range from the slot
  let slotTime = '';
  if (slot) {
    // Check if the slot starts with "TOM" (for tomorrow's slots)
    let processedSlot = slot;
    if (slot.startsWith('TOM_')) {
      processedSlot = slot.replace('TOM_', ''); // Remove the "TOM_" prefix
    }

    // Extract the time range from the slot by splitting it by underscore (_)
    const slotParts = processedSlot.split('_');
    const startTime = slotParts[1]; // e.g., "0800PM"
    const endTime = slotParts[2]; // e.g., "0830PM"

    // Convert the slot times to a more readable format
    const formattedStartTime = convertSlotTimeTo12HourFormat(startTime);
    const formattedEndTime = convertSlotTimeTo12HourFormat(endTime);

    // Construct the time range (e.g., "08:00 PM - 08:30 PM")
    slotTime = `${formattedStartTime} - ${formattedEndTime}`;
  }

  // Return the formatted date and slot time
  return `${formattedDate}, ${slotTime}`;
};

// Helper function to convert slot times to 12-hour format (e.g., "0830PM" => "08:30 PM")
const convertSlotTimeTo12HourFormat = slotTime => {
  const hour = parseInt(slotTime.slice(0, 2), 10);
  const minute = slotTime.slice(2, 4);
  const period = slotTime.slice(4); // AM or PM

  const formattedHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;
  return `${formattedHour}:${minute} ${period}`;
};
