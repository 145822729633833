import { jwtDecode } from 'jwt-decode';

export function useLoginStatus() {
  const defaultState = { token: null, isLoggedIn: false };
  try {
    const token = localStorage.getItem('token');

    if (token) {
      const decodeToken = jwtDecode(token, { header: true });
      const decodeUser = jwtDecode(token);

      if (Date.now() >= decodeToken?.exp * 1000) {
        localStorage.removeItem('token');
        return false;
      }
      return { token: token, isLoggedIn: true, user: decodeUser };
    }

    return defaultState;
  } catch (error) {
    localStorage.removeItem('token');
    return defaultState;
  }
}
